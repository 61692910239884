const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// busca as imagens no servidor e converte para base64
function urlContentToDataUri(url) {
  const fetchAsBlob = url => fetch(url)
    .then(response => response.blob());

  const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
  return new Promise((resolve, reject) => {
    fetchAsBlob(url)
      .then(convertBlobToBase64)
      .then((r) => {
        resolve(r)
      }).catch((rej) => {
        reject(rej)
      });
  });
}

// ajusta as colunas para o tamanho da tabela
const collunsLength = (body) => {
  let bodyLength = body[0].length
  let width = []
  for (let index = 0; index < bodyLength; index++) {
    width.push('auto')
  }
  width[width.length - 1] = '*'
  return width;
}

const pdf = async (body, title, subTitle, filename) => {
  // subtitulo opcional
  let optionSubTitle = subTitle
  let optionSubTitleAling =  "left"
  // carregando o logo da STV
  let lg64 = null;
  if (process.env.NODE_ENV === 'production') {
    lg64 = await urlContentToDataUri(window.location.origin + "/images/MASTER100px.png");
  } else {
    lg64 = await urlContentToDataUri("http://localhost:3333/images/MASTER100px.png");
  }
  // caso não venha a imagem do logo da STV seto como null
  if (lg64) {
    lg64 = lg64.includes('data:image/png;base64,') ? lg64 : null;
  }
  // dados para montar a pagina
  var dd = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [ 20, 30, 20, 30 ],
    footer: function (currentPage, pageCount) { return { text: currentPage.toString() + ' de ' + pageCount, alignment: 'center' } },
    content: [{
      columns: [
        lg64 ? { image: lg64, width: 50 } : { text:"", width: 50},
        {
          text: `\n${title}`,
          style: 'header',
          alignment: 'center'

        },
      ],
    },
    { text: optionSubTitle, alignment: optionSubTitleAling },
    {
      style: 'tableExample',
      table: {
        headerRows: 1,

        widths: collunsLength(body),
        body: [
          //recebe os dados da tabela
          ...body
        ]
      },
      layout: 'lightHorizontalLines'
    },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
      }
    },
  }
  // gerando o pdf e o download
  pdfMake.createPdf(dd).download(`${filename}.pdf`);
}

exports.orderReport = async (data, title, subtitle, filename) => {
  if (!Array.isArray(data)) {
    return null
  }
  if (data.length < 1) {
    return null
  }
  let style = "tableHeader";
  let alignment = "center";
  let fontSize = 10;
  let body = [
    [
      // { text: "ID", style, alignment },
      { text: "OC", style, alignment, fontSize },
      { text: "Fornecedor", style, alignment, fontSize },
      { text: "Obra", style, alignment, fontSize },
      { text: "Material", style, alignment, fontSize },
      { text: "Quant.", style, alignment, fontSize },
      { text: "Enviado", style, alignment, fontSize },
      { text: "Direcionado", style, alignment, fontSize },
      // { text: "Descrição", style, alignment, fontSize },
      { text: "Motorista", style, alignment, fontSize },
      { text: "Placa", style, alignment, fontSize },
      { text: "Carregado", style, alignment, fontSize },
      { text: "Fin. por", style, alignment, fontSize },
      { text: "Fin. em", style, alignment, fontSize },
      { text: "Desc. fin.", style, alignment, fontSize },
      { text: "Dist. KM", style, alignment, fontSize },
    ],
  ];

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      body.push([
        // { text: e.id, alignment, fontSize: (fontSize - 2) },
        { text: e.buyOrderId, alignment, fontSize: (fontSize - 2) },
        { text: e.provider, alignment, fontSize: (fontSize - 2) },
        { text: e.construction, alignment, fontSize: (fontSize - 2) },
        { text: e.itemsStr, alignment, fontSize: (fontSize - 2) },
        { text: e.colectedAmount, alignment, fontSize: (fontSize - 2) },
        { text: e.createUser, alignment, fontSize: (fontSize - 2) },
        { text: e.createdAt, alignment, fontSize: (fontSize - 2) },
        // { text: e.description, alignment, fontSize: (fontSize - 2) },
        { text: e.driverUser, alignment, fontSize: (fontSize - 2) },
        { text: e.plate, alignment, fontSize: (fontSize - 2) },
        { text: e.loadedDate, alignment, fontSize: (fontSize - 2) },
        { text: e.finishedByUser, alignment, fontSize: (fontSize - 2) },
        { text: e.finishedDate, alignment, fontSize: (fontSize - 2) },
        { text: e.finishedComment, alignment, fontSize: (fontSize - 2) },
        { text: e.distanceStr, alignment, fontSize: (fontSize - 2) },
      ]);
    }
  }

  let pdfDATA = await pdf(body, title, subtitle, filename);
  return pdfDATA
}

exports.orderReportHistory = async (data, title, subtitle, filename) => {
  if (!Array.isArray(data)) {
    return null
  }
  if (data.length < 1) {
    return null
  }
  let style = "tableHeader";
  let alignment = "center";
  let fontSize = 10;
  let body = [
    [
      { text: "ID", style, alignment },
      { text: "Enviado por", style, alignment, fontSize },
      { text: "Direcionado", style, alignment, fontSize },
      { text: "Descrição", style, alignment, fontSize },
      { text: "Motorista", style, alignment, fontSize },
      { text: "Placa", style, alignment, fontSize },
      { text: "Carregado", style, alignment, fontSize },
      { text: "Carregado em", style, alignment, fontSize },
      { text: "Finalizado por", style, alignment, fontSize },
      { text: "Finalizado em", style, alignment, fontSize },
      { text: "Desc. finalização", style, alignment, fontSize },
    ],
  ];

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      body.push([
        { text: e.id, alignment, fontSize: (fontSize - 2) },
        { text: e.createUser, alignment, fontSize: (fontSize - 2) },
        { text: e.createdAt, alignment, fontSize: (fontSize - 2) },
        { text: e.description, alignment, fontSize: (fontSize - 2) },
        { text: e.driverUser, alignment, fontSize: (fontSize - 2) },
        { text: e.plate, alignment, fontSize: (fontSize - 2) },
        { text: e.colectedAmount, alignment, fontSize: (fontSize - 2) },
        { text: e.loadedDate, alignment, fontSize: (fontSize - 2) },
        { text: e.finishedByUser, alignment, fontSize: (fontSize - 2) },
        { text: e.finishedDate, alignment, fontSize: (fontSize - 2) },
        { text: e.finishedComment, alignment, fontSize: (fontSize - 2) },
      ]);
    }
  }

  let pdfDATA = await pdf(body, title, subtitle, filename);
  return pdfDATA
}