const ExcelJS = require('exceljs');

exports.generate = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'STV Segurança';
  workbook.created = new Date();
  try {
    if (!Array.isArray(data)) {
      console.log('data not correct');
      throw 'data not correct'
    }
    let contaisData = false
    data.forEach(e => {
      if (!e.rows.length) {
        console.log('data no length')
        return
      }
      contaisData = true
      const worksheet = workbook.addWorksheet(e.nameSheet);

      worksheet.columns = e.columns

      if (Array.isArray(e.rows)) {
        e.rows.forEach(row => {
          let rowD = {}
          for (let valor in row) {
            rowD[valor] = row[valor]
          }
          worksheet.addRow(rowD);
        });
      }
      worksheet.getRow(1).font = { size: 12, bold: true };
      worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    })


    if (contaisData) {
      return await workbook.xlsx.writeBuffer();
    } else {
      return null
    }

  } catch (error) {
    console.log(error);
    return null
  }
};
